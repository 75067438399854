@tailwind base;
@tailwind components;
@tailwind utilities;

// @import '../assets/fonts/Agrandir.css';

body {
  font-family: 'Agrandir', sans-serif;
}


.swiper {
  width: 100%;
  margin-top: 20px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  //  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  height: 100%;
  object-fit: cover;
}

.swiper .swiper-button-prev::after,
.swiper .swiper-button-next::after {
  font-size: 1.5rem;
}

nav {
  -webkit-transform: translate3d(0, 0, 0);
}

.main-bottom {
  padding-bottom: 12rem;
}

@media (max-width: 1250px) {
  .main-bottom {
    padding-bottom: 2rem;
  }
}

.swiper-pagination-bullet {
  display: none !important;
}

// font classes

.roboto-thin {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-regular {
  font-family: "Roboto", sans-serif !important;
  font-weight: 400;
  font-style: normal;
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.roboto-black {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-thin-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.roboto-light-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.roboto-regular-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.roboto-medium-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.roboto-bold-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.roboto-black-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: italic;
}

@media (max-width: 415px) {
  .custom-wrap {
    flex-wrap: wrap;
  }
}
@media (max-width: 390px) {
  .custom-wrap-center {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.shape {
  position: relative;
  border-bottom: 6px solid #0f6485; 
}
.custom-div {
  position: absolute;
  bottom: -7px;
  left: 0;
  width: inherit;
  width: 100%;
  height: 53px;
  border-bottom: 2px solid black;
}